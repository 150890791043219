
//
// apexcharts.scss
//
.apex-charts {
    min-height: 10px !important;
    text {
        font-family: $font-family-base !important;
        fill: var(--#{$prefix}gray-500);
    }
    .apexcharts-canvas {
        margin: 0 auto;
    }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-family: $font-family-base !important;

}

.apexcharts-legend-series {
    font-weight: $font-weight-medium;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: var(--#{$prefix}gray-100);
}

.apexcharts-legend-text {
    color: var(--#{$prefix}gray-600) !important;
    font-family: $font-family-base !important;
    font-size: 13px !important;
    font-weight: 600 !important;
}

.apexcharts-pie-label {
    fill: var(--#{$prefix}white) !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        fill: var(--#{$prefix}gray-500);
    }
    line{
        stroke: var(--#{$prefix}border-color);
    }
}

.apexcharts-grid-borders{
    stroke: var(--#{$prefix}border-color)  !important;
}

#treemap-chart {
    &.apex-charts text {
        fill: var(--#{$prefix}white) !important;
    }
}

[data-bs-theme="dark"] {
    .apexcharts-gridline{
        stroke: var(--#{$prefix}border-color);
    }
    .apexcharts-radialbar-track.apexcharts-track {
        path {
            stroke:  var(--#{$prefix}border-color) !important;
        }
    }
    .apexcharts-tooltip{
        background-color:  var(--#{$prefix}secondary-bg) !important;
        border: 1px solid var(--#{$prefix}border-color) !important;

        .apexcharts-tooltip-title{
            background-color: var(--#{$prefix}tertiary-bg) !important;
            border-bottom: 1px solid var(--#{$prefix}border-color) !important;
        }
    }
    .apexcharts-pie-area{
        stroke:  var(--#{$prefix}border-color) ;
    }
    .apexcharts-pie-label{
        fill: var(--#{$prefix}tertiary-bg) !important;
    }

    .apexcharts-xcrosshairs{
            stroke: var(--#{$prefix}border-color) !important;
    }

    .apexcharts-grid{
        line{
            stroke: var(--#{$prefix}border-color) !important;
        }
    }

    .apexcharts-grid-borders{
        line{
            stroke: var(--#{$prefix}border-color) !important;
        }
    }

}